import getRoute from '~/utilities/configs/apiMap';
import { profilePageStore } from '~/store/profilePage';
import { authStore } from '~/store/auth';
import type { ProfileItem } from '~/types/profile-page-items';
import { apiGet, apiPost } from '~/services/api';

export default defineNuxtRouteMiddleware(async (to) => {
  if (!import.meta.client) {
    return
  }

  const localToken = localStorage.getItem('acc_token');

  if (!localToken) {
    return navigateTo('/?login=true');
  }

  const useAuthStore = authStore();
  const useProfilePageStore = profilePageStore();
  await useAuthStore.getUser();

  if (!useAuthStore.$state.isUserAuth) {
    return navigateTo('/?login=true');
  }

  try {
    const endpoint = getRoute({ endpont: 'get_profiles' });
    const token = localStorage.getItem('acc_token');
    const response = await apiGet({
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('middle', response?.status.value);
    if (response?.status.value === 'success') {
      const profileObject = response?.data.value[0];

      if (profileObject) {
        const secondEndpoint = getRoute({
          endpont: 'get_profile_by_id',
          id: profileObject.id,
        });
        const secondResponse = await apiGet({
          url: secondEndpoint,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (secondResponse?.status.value === 'success') {
          console.log('profile data', secondResponse?.data.value);
          const profileData = secondResponse?.data.value;
          useProfilePageStore.$state.profilePageData = profileData as ProfileItem;
        }
      } else {
        (useProfilePageStore.$state.profilePageData ??=
          {} as ProfileItem).primaryEmail = 'example@gmail.com';
        (useProfilePageStore.$state.profilePageData ??=
          {} as ProfileItem).companyName =
          useAuthStore.$state.userData.companyName;
      }
    }

    // if (!response.data.value || response.error.value) {
    //   return navigateTo("/404");
    // }

    // useProfilePageStore.$state.profilePageStore = response.data.value as ProfileItem;
    // useProfilePageStore.$state.profilePageData = {
    // //   data which was saved on server by the user earlier
    // };

    // useAuthStore.$state.isUserAuth = true;
  } catch (error) {
    return navigateTo('/404');
  }
});
